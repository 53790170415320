footer {
  text-align: center;
  margin: 30px 0;
}
footer a {
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}

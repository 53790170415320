.hero {
  background-color: #394046;
  background-position: center;
  background-size: cover;
  padding: 30px 0;
  margin-bottom: 20px;
}

.hero h2 {
  color: white;
}
.hero .sold-out {
  margin: 0;
}

// Pico.css (https://picocss.com)
@import "@picocss/pico/scss/pico";

/*!
 * Bootstrap Grid 5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// $include-column-box-sizing: true;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@import "bootstrap/scss/bootstrap-grid";

.nftDetails {
  header {
    display: flex;
    justify-content: space-between;

    .gallery-nav-wrapper {
    }
  }
  .download {
    margin-top: 10px;
    text-align: center;
  }
  .owner {
    margin-top: 20px;
  }
}

.gallery-head {
  display: flex;
  justify-content: space-between;
  .gallery-filters {
    text-align: right;
    fieldset {
      margin: 20px 0 0 0;
    }
    // display: flex;
    // align-items: center;
  }
}
